import ActionCreator from './ActionCreator';

async function dispatchRouteInitClientActions({ store, location }) {
  let { appActions } = ActionCreator(store.dispatch);

  if (!store.getState().user.data) {
    await appActions.autoLogin();
  }
}

export { dispatchRouteInitClientActions };
