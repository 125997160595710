import React, { Component } from 'react';
import styled from 'styled-components';
import LangSwitcher from './LangSwitcher';
import * as Icon from './Icon';
import PathPrefix from '../Utils/PathPrefixUtil';
import { white } from 'material-ui/styles/colors';
import * as Widget from './Widget';

class Footer extends Component {
  render() {
    let { extraStyle = '' } = this.props;

    return (
      <Wrapper extraStyle={extraStyle}>
        <Content>
          <div>
            <Footer.CompanyLogo
              image={PathPrefix('/images/pages/footer/home_footer_logo.png')}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={PathPrefix(
                  '/images/pages/footer/home_footer_copyrights.png'
                )}
                style={{ marginTop: 20, width: 250, height: 20 }}
                alt="copyrights"
              />
            </div>
          </div>
          <Footer.CompanyInfo>
            <span>
              {' '}
              <h5>CONTACT</h5>
            </span>

            <Widget.Center>
              <div>
                <h5 className="infoLeft">
                  <Icon.Call color={white} />
                  &nbsp;<a href="tel: +886-917-640-369" style={{ color: 'white' }}>+886-917-640-369</a>
                </h5>
                <h5 className="infoLeft">
                  <Icon.Call color={white} />
                  &nbsp;<a href="tel: +886-965-327-197" style={{ color: 'white' }}>+886-965-327-197</a>
                </h5>
                <h5 className="infoLeft tel" >
                  <Icon.Call color={white} />
                  &nbsp;<a href="tel: 02-2368-7916" style={{ color: 'white' }}>02-2368-7916</a>
                </h5>
              </div>
              <div>
                <h5 className="infoRight">
                  <Icon.Print color={white} />
                  &nbsp;02-2368-7906
                </h5>
                <h5 className="infoRight">
                  <Icon.Email color={white} />
                  &nbsp;chig20170720@gmail.com
                </h5>
                <h5 className="infoRight">
                  <Icon.Location color={white} />
                  &nbsp;台北市中正區廈門街131巷2-1號2樓-2
                </h5>
              </div>
            </Widget.Center>
          </Footer.CompanyInfo>

          <div>
            <LangSwitcher extraCss="top:0;" />
            <div className="logos">
              <a href={'https://www.facebook.com/chigInteriordesign/'}>
                <img
                  src={PathPrefix('/images/pages/footer/home_FB.png')}
                  style={{ margin: 5, justifyContent: 'center', width: '30px' }}
                  alt="fb"
                />
              </a>
              <a
                href={
                  'https://www.pinterest.com/0fwh2ogvurxspke408jsy7q956nk6b'
                }
              >
                <img
                  src={PathPrefix('/images/pages/footer/home_pinterest.png')}
                  style={{ margin: 5, justifyContent: 'center', width: '30px' }}
                  alt="pinterest"
                />
              </a>
            </div>
          </div>
        </Content>
      </Wrapper>
    );
  }
  static CompanyLogo = styled.div`
    background-image: url(${props => props.image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    /* height: 100vh; */
    min-height: 120px;
    text-align: center;
    margin: 0;
  `;
  static CompanyInfo = styled.div`
    display: flex;
    color: white;
    flex-direction: column;
    & > span {
      margin: 20px;
      & > h5 {
        text-align: center;
        margin: 0 auto;
        display: block;
        width: 80px;
        border-bottom: 1px solid white;
      }
    }
    & > div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: nowrap;
      & > div {
        position: relative;
        margin: 10px;
        min-width: 260px;
        & > h5 {
          margin: 10px 0px;
          display: flex;
          align-items: center;
          flex-shrink: 0;
          & > svg {
            flex-shrink: 0;
          }
        }
        .infoLeft {
          justify-content: flex-end;
        }
        .infoRight {
          justify-content: flex-start;
        }
        .tel { /* hard code to justify ui */
          position: absolute;
          left: 116px;
          top : 69px;
        }
      }
      @media screen and (max-width: 600px) {
        /* flex-wrap: wrap; */
        flex-direction: column;
        align-items: center;
        & > div {
          .infoLeft {
            justify-content: center;
          }
          .infoRight {
            justify-content: center;
          }
          .tel {
            position: static;
          }
        }
      }
    }
  `;
}
let Wrapper = styled.div`
  display: flex;
  background-color: #000000;
  width: 100%;
  padding: 30px 5px 30px 5px;
  color: white;
  ${props => props.extraStyle};
`;
let Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    min-width: 245px;
    & > .logos {
      padding-left: 30px;
      display: flex;
      justify-content: space-around;
    }
    & > h5 {
      display: flex;
      justify-content: center;
      margin: 5px;
    }
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 1065px) {
    flex-direction: column;
    & > div {
      margin: 20px 0px;
      & > .logos {
        display: flex;
        justify-content: center;
        & > a {
          margin: 0px 42px;
        }
      }
    }
  }
`;

export default Footer;
