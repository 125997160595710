import { combineReducers } from 'redux';
import user from './User';
import lang from './Lang';
import product from './Product';
import order from './Order';
import category from './Category';
import member from './Member';

export default combineReducers({
  user,
  lang,
  product,
  order,
  category,
  member,
});
