import { push } from 'gatsby';
import { getStore } from './Store';
import Actions from './Reducers/Actions';
import ApiProxy from './ApiProxy';
import Catchify from './Utils/Catchify';
import API from './Api';

export default dispatch => ({
  navActions: {
    push: Catchify(async url => {
      let lang = getStore().getState().lang;
      push(`/${lang}${url}`);
    }),
  },

  appActions: {
    setLang: Catchify(async lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    }),

    login: Catchify(
      async ({ username, password }) => {
        let response = await API.login({ username, password });
        ApiProxy.setToken(response.token);
        try {
          window.localStorage.setItem('token', response.token);
          window.localStorage.setItem('id', response.id);
        } catch (err) {
          console.log('cannot set localStorage! ');
        }
        dispatch({ type: Actions.LOGIN, payload: response });
      },

      async err => {
        ApiProxy.setToken(null);
        window.localStorage.removeItem('id');
        window.localStorage.removeItem('token');
        console.warn(err);
        throw err;
      }
    ),

    autoLogin: Catchify(
      async () => {
        let id = window.localStorage.getItem('id');
        let token = window.localStorage.getItem('token');
        ApiProxy.setToken(token);
        if (id) {
          let response = await API.getMyProfile();
          dispatch({ type: Actions.AUTO_LOGIN, payload: response });
        }
      },

      async err => {
        console.warn(err);
        ApiProxy.setToken(null);
        window.localStorage.removeItem('id');
        window.localStorage.removeItem('token');
        throw err;
      }
    ),

    logout: Catchify(async () => {
      ApiProxy.setToken(null);
      window.localStorage.removeItem('id');
      window.localStorage.removeItem('token');
      dispatch({ type: Actions.LOGOUT, payload: null });
    }),

    register: async ({ username, email, password }) => {
      try {
        await API.register({ username, email, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetPassword: async ({ email }) => {
      try {
        await API.resetPassword({ email });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetValidation: async ({ username, password }) => {
      try {
        await API.resetValidation({ username, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    socialSingin: async ({ socialType, token }) => {
      try {
        let response = await API.socialSingin({ socialType, token });
        ApiProxy.setToken(response.token);
        try {
          window.localStorage.setItem('token', response.token);
          window.localStorage.setItem('id', response.id);
        } catch (err) {
          console.log('cannot set localStorage! ');
        }
        dispatch({ type: Actions.LOGIN, payload: response });
        return Promise.resolve();
      } catch (err) {
        ApiProxy.setToken(null);
        window.localStorage.removeItem('id');
        window.localStorage.removeItem('token');
        console.warn(err);
        return Promise.reject(err);
      }
    },

    preCheckout: Catchify(
      async data => {
        let result = await API.preCheckout(JSON.stringify(data));
        return result;
      },
      err => Promise.reject(err)
    ),

    checkout: Catchify(
      async data => {
        let result = await API.checkout(data);
        return result;
      },
      err => Promise.reject(err)
    ),

    paypalOnSuccess: Catchify(
      async (uid, data) => {
        return await API.paypalOnSuccess(uid, JSON.stringify(data));
      },
      err => Promise.reject(err)
    ),

    getOrderList: Catchify(
      async () => {
        let result = await API.getOrderList();
        dispatch({ type: Actions.SET_ORDERS, payload: result });
        return result;
      },
      err => Promise.reject(err)
    ),

    getOrder: Catchify(
      async id => {
        let result = await API.getOrder(id);
        dispatch({ type: Actions.SET_ORDER, payload: result });
        return result;
      },
      err => Promise.reject(err)
    ),

    postContactForm: Catchify(
      async ({ name, phone, email, region, consult_type, description, captcha_response }) => {
        let result = await API.postContactForm({
          name,
          phone,
          email,
          region,
          consult_type,
          description,
          captcha_response,
        });
        console.log('result', result);
        return result;
      },
      err => Promise.reject(err)
    ),
  },
});
