export const SupportLangs = [
  { display: '繁體中文', key: 'tw' },
  { display: 'English', key: 'en' },
];

export const DefaultLang = 'tw';

const StringTable = {
  tw: {
    home: '首頁',
    about: '公司簡介',
    flow: '服務流程',
    category: '作品展示',
    blog: '部落格',
    contact: '聯絡我們',
    welcome: '歡迎!',
    cancel: '取消',
    'all-fields-required': '所有欄位皆須填寫',
    register: '註冊',
    login: '登入',
    logout: '登出',
    'log-in-with-facebook': '以Facebook帳號登入',
    'log-in-with-google': '以Google帳號登入',
    username: '帳號',
    email: '電子信箱',
    password: '密碼',
    'must-have-username-and-password': '帳號及密碼為必填!',
    'error-login-message': '帳號密碼錯誤!',
    'username-used': '此帳號已被使用！',
    'email-used': '此電子信箱已被使用！',
    'error-register-message': '註冊失敗！',
    'error-message': '發生錯誤',
    'already-member': '已經有帳號了？',
    'not-member': '還沒有帳號？',
    'forget-password': '忘記密碼？',
    'email-not-found': '此電子信箱不存在！',
    'error-reset-password-message': '重設密碼失敗！',
    'reset-password': '重設密碼',
    'back-to': '回到',
    'reset-password-success-message': '新密碼已寄至您的電子信箱。',
    'reset-validation': '重寄認證信',
    'reset-validation-message':
      '您的帳號已註冊成功，\n但尚未通過認證。\n請收取認證信並點選連結。',
    'reset-validation-success-message': '認證信已寄出！',
    'err-reset-validation-message': '寄件失敗！',
    'payment-success': '已付款',
    'payment-failure': '付款失敗',
    'payment-waiting': '尚未付款',
    'payment-processing': '付款中',
    'payment-type-ecpay': '線上刷卡',
    'payment-type-paypal': 'PAYPAL',
    'payment-type-offline': '銀行轉帳',
    'login-first': '請先登入！',
    'no-order-or-no-right-access-order': '無此訂單或您無權限瀏覽此訂單',
    'submit-contact-form-error': 'Oops!發生錯誤，請再試一次！',
    submit: '送出',
    'field-required': '請確認所有必填欄位已填寫！',
    'contact-us': 'CONTACT US',
    'online-contact-form-title': '線上聯絡表單',
    'online-contact-form-subtitle': '歡迎留下您的聯絡方式，我們會盡快與您聯繫',
    phone: '電話',
    fax: '傳真',
    address: '地址',
    'address-data': '台北市中正區廈門街131巷2-1號2樓-2',
    'email-data': 'chig20170720@gmail.com',
    name: '姓名',
    region: '所在地區',
    'consult-type': '諮詢類型',
    'contact-text': '留言內容',
    'consult-type-house': '住宅',
    'consult-type-office': '商空',
    'consult-type-building': '建築',
    'consult-type-decorate': '裝修',
    'consult-type-brand': '品牌設計',
    'consult-type-others': '其他',
    'contact-form-send-success': '謝謝您！我們將盡快與您聯絡！',
    'about-title-1': '麒心協力',
    'about-title-2': '鉅作綻放',
    'about-description':
      '設計\n源自於與業主的溝通及人性化的配置\n執著、服務、品質\n在我們的設計哲學中\n複雜由簡約而起，簡約由複雜而生\n以材料來製造空間，以家飾來創造靈魂\n我們以最真誠的態度，迎接挑戰',
    'about-subtitle': '溝通、需求、巧思、創意、品質、服務為宗旨',
    'about-vision-title': '公司願景',
    'about-vision-content':
      '建立雙向的溝通\n尊重業主的需求\n啟發客戶的創意\n發揮設計的巧思\n完善工程的品質\n延續尊榮的服務',
    'about-culture-title': '公司文化',
    'about-culture-content':
      '麒鉅設計精神在於"齊聚"\n我們將每位客戶當作家人聚在一起\n提供最貼心的關心及服務\n我們將每位設計師當作夥伴聚在一起\n提供幸福的環境及服務\n我們將每位廠商聚在一起\n讓他們用專業的技術及品質服務每位客戶',
    'about-strategy-title': '經營策略',
    'about-strategy-content':
      '建立完善的行政及法律體制\n提供設計師能無後顧之憂的發揮專長\n提供有能力及想創業的設計師完善的資源\n設置創意分享機制\n提供設計師能更多元的討論空間\n提供客戶有更多樣空間思考選擇',
    'please-check-recaptcha': '請勾選機器人驗證！',
    'submit-form-recaptcha-error': '機器人驗證失敗！請重新整理頁面！',
    'charging-method-title': '收費方式',
    'charging-method-content': '室內設計費用：6000元/坪起\n工程管理費用：總工程造價10%起',
    "go-next-page": "下一頁",
    "go-prev-page": "上一頁"
  },
  en: {
    home: 'home',
    about: 'about',
    flow: 'flow',
    category: 'category',
    blog: 'blog',
    contact: 'contact',
    welcome: 'Welcome!',
    cancel: 'Cancel',
    'all-fields-required': 'All fields are required.',
    register: 'Register',
    login: 'Login',
    logout: 'Logout',
    'log-in-with-facebook': 'Log in with Facebook',
    'log-in-with-google': 'Log in with Google',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    'must-have-username-and-password':
      'Please enter both username and password!',
    'error-login-message': 'Fail to login!',
    'error-register-message': 'Fail to register!',
    'username-used': 'The username is already used!',
    'email-used': 'The email is already used!',
    'error-message': 'Error happen',
    'already-member': 'Already a member?',
    'not-member': 'Not a member?',
    'forget-password': 'Forgot Password?',
    'email-not-found': "Can't find this email.",
    'error-reset-password-message': 'Fail to reset password!',
    'reset-password': 'Reset Password',
    'back-to': 'Back to',
    'reset-password-success-message':
      'Your new password has been sent to your email.',
    'reset-validation': 'Resend Validation Email',
    'reset-validation-message':
      'Your account is registered but not validated.\nPlease receive the validation email and click the link.',
    'reset-validation-success-message': 'The validation email is sent!',
    'err-reset-validation-message': 'Fail to send email!',
    'payment-success': 'Success',
    'payment-failure': 'Failure',
    'payment-waiting': 'Waiting',
    'payment-processing': 'Processing',
    'payment-type-ecpay': 'Credit Card',
    'payment-type-paypal': 'PAYPAL',
    'payment-type-offline': 'ATM',
    'login-first': 'Please login first!',
    'no-order-or-no-right-access-order':
      'This order does not exist or you have no right to access this order.',
    'submit-contact-form-error': 'Oops! Error occurs! Please retry!',
    submit: 'submit',
    'field-required': 'Check All Required Form!',
    'contact-us': 'CONTACT US',
    'online-contact-form-title': 'Online Contact Form',
    'online-contact-form-subtitle': "We'll Contact You Soon!",
    phone: 'Phone',
    fax: 'FAX',
    address: 'Address',
    'address-data': '新北市新莊區銘德街49巷7號',
    'email-data': 'chig20170720@gmail.com',
    name: 'Name',
    region: 'Region',
    'consult-type': 'Consult Type',
    'contact-text': 'Comment',
    'consult-type-house': 'House',
    'consult-type-office': 'Office',
    'consult-type-building': 'Building',
    'consult-type-decorate': 'Decorate',
    'consult-type-brand': 'Brand',
    'consult-type-others': 'Others',
    'contact-form-send-success': 'Sending Success!',
    'about-title-1': 'about-title-1',
    'about-title-2': 'about-title-2',
    'about-description': 'about-description',
    'about-subtitle': 'about-subtitle',
    'about-vision-title': 'about-vision-title',
    'about-vision-content': 'about-vision-content',
    'about-culture-title': 'about-culture-title',
    'about-culture-content': 'about-culture-content',
    'about-strategy-title': 'strategy',
    'about-strategy-content': 'about-strategy-content',
    'please-check-recaptcha': "please check I'm not a robot.`",
    'submit-form-recaptcha-error':
      'verify robot failed, please reload the page',
    'charging-method-title': 'Charging Method',
    'charging-method-content': '室內設計費用：6000元/坪起\n工程管理費用：總工程造價10%起',
    "go-next-page": "Next",
    "go-prev-page": "Prev"
  },
};

export default StringTable;
