import React, { Component } from "react";
import styled from "styled-components";
import "./index.css";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { Helmet } from "react-helmet";
import favicon from "./../static/favicon.ico";
import MuiWrapper from "./MuiWrapper";
import Constants from "./Domain/constants";

class Layout extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  render() {
    let transFlag = this._isNavbarTransparentInPage();

    return (
      <MuiWrapper>
        <Layout.Wrapper isNavbarTransparentInPage={transFlag}>
          {/* workaround for fb og image */}
          <div
            className="placeholder-og-image"
            style={{ display: "none", width: 894, height: 429 }}
          >
            <img
              src={`${Constants.webUrl}/images/pages/home/home_banner_1.jpg`}
            />
          </div>

          <Helmet>
            <title>麒鉅設計</title>
            <meta name="description" content="麒鉅設計 Chigi design" />
            <link rel="shortcut icon" href={favicon} />
          </Helmet>
          <Navbar
            passBreakpoint={!!this.passBreakpoint}
            isNavbarTransparentInPage={transFlag}
          />

          <Layout.Content>{this.props.children}</Layout.Content>
          <div className="fab-messeger">
            <a href="https://m.me/chigInteriordesign/" target="_blank">
              <img
                src="/images/icon-facebook-messenger.png"
                alt="fb-messenger-button"
              />
            </a>
          </div>
          <Footer />
        </Layout.Wrapper>
      </MuiWrapper>
    );
  }

  _isNavbarTransparentInPage = () => {
    let {
      location: { pathname },
    } = this.props;

    if (pathname.slice(0, 2) === "//") {
      pathname = pathname.slice(1);
    }

    if (pathname.slice(-1)[0] !== "/") {
      pathname += "/";
    }

    // if (['/', '/tw/', '/en/'].some(p => pathname === p)) {
    //   return true;
    // }
    return false;
  };

  _onScroll = (e) => {
    const breakpoint = 600;
    if (!window) {
      return;
    }

    // document for older IE
    let scrollTop =
      window.scrollY ||
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        console.log("+++ breakpoint +++");
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        console.log("--- breakpoint ---");
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

Layout.Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: white;
  padding-top: ${(props) => (props.isNavbarTransparentInPage ? "0px" : "70px")};
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  & .fab-messeger {
    position: fixed;
    right: 10px;
    bottom: 10px;
    & a {
      display: block;
    }
    & img {
      width: 40px;
      height: 40px;
      display: block;
    }
  }
`;

Layout.Content = styled.div`
  flex: 1;
  align-self: stretch;
`;

export default Layout;
