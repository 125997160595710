export function findCategory(subtree, targetId) {
  if (subtree.id === targetId) {
    return subtree;
  }

  for (let i = 0; i < subtree.children.length; i++) {
    let nextLevelTree = subtree.children[i],
      targetTree;
    targetTree = findCategory(nextLevelTree, targetId);
    if (targetTree) {
      return targetTree;
    }
  }

  return undefined;
}

export function getParentCategoriesBacktrace(catTree, categoryId) {
  let category = findCategory(catTree, categoryId);
  if (!category) {
    return [];
  }

  let backtrace = [],
    currentCate = category;
  while (currentCate) {
    backtrace.push(currentCate);
    if (!currentCate.parent) {
      break;
    }
    currentCate = findCategory(catTree, currentCate.parent.id);
  }

  return backtrace.reverse();
}
