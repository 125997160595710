import { findCategory } from '../Domain/Category';
import * as _ from 'lodash';

export default function(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function getOwnItemsById(state, id) {
  if (id === 0) {
    let result = [];
    state.children
      .filter(c => c.id !== 1 && c.id !== 8) // exclude id = 1 (blog 1 category),  exclude id = 8 (blog 2 category)
      .map(c => (result = result.concat(c.own_items)));
    return _.uniq(result);
  }
  let cat = findCategory(state, id);
  return (cat && cat.own_items) || [];
}

export function getCategoryById(state, id) {
  return findCategory(state, id);
}
