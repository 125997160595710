import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import Selectors from '../Selectors';

const LinkWithLangPrefix = props => {
  let { className, extraStyle, children, to, lang } = props;

  return (
    <Link className={className} style={extraStyle} to={`/${lang}${to}`}>
      {children}
    </Link>
  );
};

export default connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  null
)(LinkWithLangPrefix);
