import React from 'react';
import styled from 'styled-components';
import Link from './Link';
import * as Widget from './Widget';
import * as Icon from './Icon';
import * as L from '../Utils/Lang';

const Routes = [
  { display: 'home', route: '/' },
  { display: 'about', route: '/about' },
  { display: 'category', route: '/category' },
  { display: 'blog', route: '/blog' },
  { display: 'flow', route: '/flow' },
  { display: 'contact', route: '/contact' },
];
class Navbar extends React.Component {
  state = {
    open: false,
  };

  render() {
    let { passBreakpoint, isNavbarTransparentInPage } = this.props;

    return (
      <Navbar.Wrapper
        passBreakpoint={passBreakpoint}
        isNavbarTransparentInPage={isNavbarTransparentInPage}
      >
        <Navbar.Content>
          <img src={'/images/pages/footer/home_logo.png'} alt="logo" />
          <div className="navs">
            {Routes.map((item, idx) => (
              <Link key={idx} to={item.route} className="nav-item">
                <Widget.FlatButton
                  label={L.s(item.display)}
                  labelStyle={{ color: 'white' }}
                />
              </Link>
            ))}
            <a href={'https://www.facebook.com/chigInteriordesign/'} id="fb">
              <img
                src={'/images/pages/footer/home_FB.png'}
                style={{ width: 30 }}
                alt="fb"
              />
            </a>
            <div
              className="app-icon"
              onClick={() => this.setState({ open: true })}
            >
              <Icon.Apps color="white" />
            </div>
          </div>
        </Navbar.Content>

        <Navbar.MenuWrapper>
          <Navbar.BackDrop
            open={this.state.open}
            onClick={() => this.setState({ open: false })}
          />
          <Navbar.Menu
            open={this.state.open}
            onClick={e => {
              e.stopPropagation();
              this.setState({ open: false });
            }}
          >
            {Routes.map((item, idx) => (
              <Link
                to={item.route}
                key={idx}
                extraStyle={{ textDecoration: 'none' }}
              >
                <Widget.MenuItem
                  primaryText={L.s(item.display)}
                  style={{ color: 'white' }}
                />
              </Link>
            ))}
            <a href={'https://www.facebook.com/chigInteriordesign/'} id="fb">
              <img
                src={'/images/pages/footer/home_FB.png'}
                style={{ width: 30, margin: 20 }}
                alt="fb"
              />
            </a>
          </Navbar.Menu>
        </Navbar.MenuWrapper>
      </Navbar.Wrapper>
    );
  }
}

Navbar.Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #3b2946;
  display: ${props =>
    props.isNavbarTransparentInPage
      ? props.passBreakpoint
        ? 'block'
        : 'none'
      : 'block'};
  padding: 0px 20px;
  z-index: 1000;
  transition: background-color 0.5s;
`;

Navbar.Content = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  height: 70px;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 0px 3px #444);
  & > img {
    @media screen and (max-width: 460px) {
      max-width: 305px;
    }
    @media screen and (max-width: 380px) {
      max-width: 250px;
    }
  }

  & > .site-title {
    color: white;
  }

  & > .navs {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    #fb {
      height: 30px;
      @media screen and (max-width: 920px) {
        display: none;
      }
    }
    & > .nav-item {
      margin-right: 10px;
      color: white;
    }

    & > .app-icon {
      display: none;
    }
  }

  @media screen and (max-width: 920px) {
    & > .navs {
      & > .nav-item {
        display: none;
      }
      & > .app-icon {
        display: initial;
        cursor: pointer;
      }
    }
  }
`;

Navbar.BackDrop = styled.div`
  background-color: ${props =>
    props.open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)'};
  width: 100vw;
  height: 100vh;
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
  transition: background-color 0.2s linear;
`;

Navbar.Menu = styled.div`
  z-index: 1000;
  height: 100vh;
  width: 300px;
  position: fixed;
  background-color: #3b2946;
  top: 0px;
  transform: ${props =>
    props.open ? 'translate(0px, 0px)' : 'translate(-300px, 0px)'};
  transition: transform 0.2s linear;
  pointer-events: auto;
`;

Navbar.MenuWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  pointer-events: none;
`;

export default Navbar;
