import * as _User from './Reducers/User';
import * as _Lang from './Reducers/Lang';
import * as _Order from './Reducers/Order';
import * as _Category from './Reducers/Category';
import * as _Member from './Reducers/Member';
import * as _Product from './Reducers/Product';
import { namedExportWithMemorizedOnce } from './Utils/FpUtil';
import ParseQuery from './Utils/ParseQuery';

let User = namedExportWithMemorizedOnce(_User);
let Lang = namedExportWithMemorizedOnce(_Lang);
let Order = namedExportWithMemorizedOnce(_Order);
let Category = namedExportWithMemorizedOnce(_Category);
let Member = namedExportWithMemorizedOnce(_Member);
let Product = namedExportWithMemorizedOnce(_Product);

export default {
  getQueryParams: ownProps => ParseQuery(ownProps.location.search),
  getLang: state => Lang.getLang(state.lang),
  getLoginUser: state => User.getLoginUser(state.user),
  getOrders: state => Order.getOrderList(state.order),
  getOrder: (state, id) => Order.getOrderDetailById(state.order, id),
  getChigiCategories: state => {
    return state.category && state.category.children.filter(c => c.id !== 1 && c.id !== 8);
  },
  getChigiBlogCategories: state => state.category && state.category.children.filter(c => c.id === 1 || c.id === 8),
  getItemsByCategoryId: (state, id) => {
    // TODO: should include the items in subtree as well rather than own items only
    return Category.getOwnItemsById(state.category, id);
  },
  getItemById: (state, id) => Product.getItemById(state.product, id),
  getCategoryById: (state, id) => {
    return Category.getCategoryById(state.category, id);
  },
  getMembers: state => Member.getMembers(state.member),
};
