import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css } from 'styled-components';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import { SupportLangs } from '../StringTable';
import * as Widget from './Widget';
import * as Icon from './Icon';

class LangSwitcher extends Component {

  static WrapperCss = css`
    flex-wrap: wrap;
    @media screen and (max-width: 450px) {
      flex-direction: row;
    }
  `;

  render() {
    let { appActions, lang, extraCss } = this.props;

    console.log('lang: ', lang);

    return (
      <Widget.Row
        justify="center"
        extraCss={LangSwitcher.WrapperCss + extraCss}
      >
        {SupportLangs.map(l => (
          <Widget.FlatButton
            key={l.key}
            icon={
              l.key === lang && (
                <Icon.Language style={{ marginRight: 20 }} />
              )
            }
            label={l.display}
            labelStyle={{ color: l.key === lang ? '#06bcd5' : '#eee' }}
            style={{ marginLeft: 10 }}
            onClick={() => {
              appActions.setLang(l.key);
              this._pushCurrentLocation();
            }}
          />
        ))}
      </Widget.Row>
    );
  }

  _pushCurrentLocation = () => {
    let { navActions } = this.props;

    if (typeof window === 'undefined') {
      return;
    } else {
      let pathname = window.location.pathname;

      if (SupportLangs.some(l => l.key === pathname.substr(1, 2))) {
        navActions.push(pathname.substr(3));
      } else {
        navActions.push(pathname);
      }
    }
  };
}

export default connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(LangSwitcher);
